import styled from 'styled-components'
import InputMask from 'react-input-mask'

import IconClose from '../../../images/icons/icon-close.svg'

import media from '../../../styles/media-query'

export const ContactWrapper = styled.div`
  ${media.lessThan('md')`
    background: linear-gradient(0deg, #386BA1 7.79%, #141930 85.83%);
    color: #ffffff;
    min-height: 100vh;
    padding: 30px;
  `}
`

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const ContactHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  justify-content: space-between;
`

export const ContactTitle = styled.h2`
  display: none;
  font-size: 1.5em;
  ${media.lessThan('md')`
    display: block;
  `}
`

export const ContactIconClose = styled(IconClose)`
  cursor: pointer;
  display: none;
  ${media.lessThan('md')`
    display: block;
  `}
`

export const ContactLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  &:not(:first-of-type) {
    margin-top: 40px;
    ${(props) =>
      !props.isOk &&
      `
      margin-top: 20px;
    `}
  }
`

export const ContactInput = styled.input`
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  height: 48px;
  padding: 0 12px;
  width: 100%;
  &::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    !props.isOk &&
    `
      border: 1px solid #F57600;
    `}
`

export const ContactInputMask = styled(InputMask)`
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  height: 48px;
  padding: 0 12px;
  width: 100%;
  &::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    !props.isOk &&
    `
      border: 1px solid #F57600;
    `}
`

export const ContactTextArea = styled.textarea`
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  height: 48px;
  font: inherit;
  padding: 16px 12px;
  height: 128px;
  width: 100%;
  &::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    !props.isOk &&
    `
      border: 1px solid #F57600;
    `}
`

export const ContactErrorText = styled.span`
  color: #f57600;
  font-size: 0.75em;
  margin-top: 8px;
  ${(props) =>
    props.isOk &&
    `
      display: none;
    `}
`

export const ContactSubmit = styled.button`
  align-self: flex-end;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.25em;
  padding: 16px 32px;
  font-weight: 700;
  height: 60px;
  width: 260px;
  margin-top: 40px;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fff;
    color: #386ba1;
  }

  ${(props) =>
    !props.isOk &&
    `
      margin-top: 20px;
    `}

  ${(props) =>
    props.state === 'loading' &&
    `
      background-color: #fff;
      color: transparent;
      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #386ba1;
        border-top-color: #fff;
        animation: spinner .6s linear infinite;
      }
    `}

  ${media.lessThan('md')`
    width: 100%;
  `}
`
