import * as React from 'react'
import axios from 'axios'

import * as S from './styled'

import { navigate } from 'gatsby'

const ContactComponent = (props) => {
  const [isNameOk, setIsNameOk] = React.useState(true)
  const [isEmailOk, setIsEmailOk] = React.useState(true)
  const [isTelOk, setIsTelOk] = React.useState(true)
  const [isMessageOk, setIsMessageOk] = React.useState(true)
  const [buttonState, setButtonState] = React.useState('initial')

  function closeContact() {
    navigate('/?contato')
  }

  function validateForm() {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const phoneRege1 = /\([1-9]\d\)\s9?\d{4}-\d{4}/
    const phoneRege2 = /\([1-9]\d\)\s\d{5}-\d{3}/

    const nameInput = document.querySelector('#name')
    if (nameInput.value) {
      setIsNameOk(true)
    } else {
      setIsNameOk(false)
    }

    const emailInput = document.querySelector('#email')
    if (emailRegex.test(emailInput.value)) {
      setIsEmailOk(true)
    } else {
      setIsEmailOk(false)
    }

    const telInput = document.querySelector('#tel')
    const telTestValue = telInput.value.trim()
    if (phoneRege1.test(telTestValue) || phoneRege2.test(telTestValue)) {
      setIsTelOk(true)
    } else {
      setIsTelOk(false)
    }

    const messageInput = document.querySelector('#message')
    if (messageInput.value) {
      setIsMessageOk(true)
    } else {
      setIsMessageOk(false)
    }

    if (!isNameOk || !isEmailOk || !isTelOk || !isMessageOk) {
      return
    }
    setButtonState('loading')

    axios
      .post('/wordpress/wp-json/reido-api/send', {
        contact_name: nameInput.value,
        contact_email: emailInput.value,
        contact_phone: telTestValue,
        contact_message: messageInput.value
      })
      .then((result) => {
        console.log(result)
      })
      .finally(() => {
        setButtonState('loaded')

        nameInput.value = ''
        emailInput.value = ''
        telInput.value = ''
        messageInput.value = ''

        setTimeout(() => {
          setButtonState('initial')
        }, 5000)
      })
  }

  function calculateText() {
    if (buttonState === 'initial') {
      return 'Enviar'
    }
    if (buttonState === 'loading') {
      return ''
    }
    if (buttonState === 'loaded') {
      return 'Enviado!'
    }
  }

  const buttonText = calculateText()

  const submitForm = (e) => {
    e.preventDefault()
    validateForm()
  }

  return (
    <S.ContactWrapper className={props.className}>
      <S.ContactHeader>
        <S.ContactTitle>Fale com a gente</S.ContactTitle>
        <S.ContactIconClose
          onClick={() => {
            closeContact()
          }}
          data-home-repairs-option-button-close
        />
      </S.ContactHeader>

      <S.ContactForm onSubmit={submitForm}>
        <S.ContactLabel type="text">Nome</S.ContactLabel>
        <S.ContactInput id="name" placeholder="Nome" isOk={isNameOk} />
        <S.ContactErrorText isOk={isNameOk}>
          Preencha o campo do nome.
        </S.ContactErrorText>

        <S.ContactLabel isOk={isNameOk}>E-mail</S.ContactLabel>
        <S.ContactInput
          id="email"
          type="email"
          placeholder="exemplo@exemplo.com"
          isOk={isEmailOk}
        />
        <S.ContactErrorText isOk={isEmailOk}>
          Preencha o campo do email.
        </S.ContactErrorText>

        <S.ContactLabel isOk={isEmailOk}>Telefone</S.ContactLabel>
        <S.ContactInputMask
          id="tel"
          type="tel"
          mask="(99) 99999-9999"
          maskChar=" "
          placeholder="(11) 98765-4321"
          disableUnderline
          isOk={isTelOk}
        />
        <S.ContactErrorText isOk={isTelOk}>
          Preencha o campo do telefone.
        </S.ContactErrorText>

        <S.ContactLabel isOk={isTelOk}>Mensagem</S.ContactLabel>
        <S.ContactTextArea
          id="message"
          type="text"
          placeholder="Escreva sua mensagem"
          isOk={isMessageOk}
        />
        <S.ContactErrorText isOk={isMessageOk}>
          Preencha o campo da mensagem.
        </S.ContactErrorText>

        <S.ContactSubmit type="submit" isOk={isMessageOk} state={buttonState}>
          {buttonText}
        </S.ContactSubmit>
      </S.ContactForm>
    </S.ContactWrapper>
  )
}

export default ContactComponent
